<template>
  <div style="height:100%; overflow: hidden; background-color:#525659;">
    <v-toolbar elevation="0" color="#414241" dark dense>
        <h3
        style="font-family:Arial;color:white;font-weight:bold;"
        class="mt-1"
        >
        {{fileName}}
        </h3>
        <v-spacer></v-spacer>
        <v-tooltip bottom transition="scroll-y-transition">
        <template v-slot:activator="{ on }">
            <v-btn
            :disabled="loadingPdf"
            @click="printPDF()"
            v-on="on"
            class="mr-3"
            text
            small
            color="white"
            >
            <v-icon> mdi-printer </v-icon>
            Print
            </v-btn>
        </template>
        <span> Print PDF </span>
        </v-tooltip>

        <v-tooltip bottom transition="scroll-y-transition">
        <template v-slot:activator="{ on }">
            <v-btn
            :disabled="loadingPdf"
            @click="downloadFile()"
            v-on="on"
            text
            small
            color="white;"
            class="mr-5"
            >
            <v-icon> mdi-download</v-icon>
            Download
            </v-btn>
        </template>
        <span> Download File </span>
        </v-tooltip>
    </v-toolbar>
    <iframe
        @load="loadingPdf = false"
        :src="pathSource"
        width="100%" 
        :height="`${$vuetify.breakpoint.height-54}px`" 
        allowfullscreen 
        frameborder="0"
        style="margin:0; padding:0px;border:0;top:0px;left:0px;bottom:0px;right:0px;"
    ></iframe>
    <v-dialog
      v-model="loadingPdf"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading PDF
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AWS from "aws-sdk";
import tunnel from "tunnel";
import config from "../config";

export default {
    data(){
        return{
            pathSource: '',
            fileName: '',
            loadingPdf: false,
        }
    },
    async mounted(){
        let fileName = this.getFileName();
        let documentType = this.getDocumentType();
        if(documentType && fileName){
            this.loadingPdf = true
            this.fileName = fileName
            let folderName = this.getFilePath(documentType);
            // this.pathSource =  await this.LoadPdf(fileName,folderName)+'#toolbar=0';
            let loadPdf = await this.LoadPdf(fileName,folderName)
            let objURL = `https://s3.us-east-2.amazonaws.com/rulebook.files`
            fetch(loadPdf, {method: 'HEAD'})
            .then(response => {
                if (response.headers.get('Content-Disposition') === 'attachment') {
                    // The browser will download the PDF file.
                    window.open( `${objURL}/CAD依頼資料/${fileName}`)
                } else {
                    // The browser will display the PDF file inline.
                    this.pathSource = loadPdf
                }
            })
            .catch(error => {
                window.open( `${objURL}/CAD依頼資料/${fileName}`)
                console.error('Error fetching PDF metadata:', error);
            });
        }
    },
    methods:{
        getFileName(){
            if(this.$route.query.file){
                return this.$route.query.file;
            }else{
                return '';
            }
        },
        getDocumentType(){
            if(this.$route.query.documentType){
                return this.$route.query.documentType;
            }else{
                return '';
            }
        },
        getFilePath(documentType){
            if(documentType=='CADRequestMaterial'){
                return 'CAD依頼資料'; //folder name
            }else if(documentType=='Rulebook'){
                return 'rulebook_attachment'; //folder name
            }else if(documentType=='Shiyo'){
                return 'Shiyo_pdf'; //folder name
            }else if(documentType=='SekkeiGyoumu'){
                return 'SekkeiGyoumu'; //folder name
            }
        },
        async LoadPdf(fileName,folderName) {
            return new Promise((resolve,reject)=>{
                try{
                    var tunnelingAgent = tunnel.httpsOverHttp({
                        proxy: {
                        // Proxy settings
                        host: "hrdproxy.hrd-s.com",
                        port: 81,
                        proxyAuth: "administrator:system",
                        },
                    });
                    AWS.config.update({
                        httpOptions: { agent: tunnelingAgent },
                        region: "us-east-2",
                        credentials: config,
                    });
                    var s3 = new AWS.S3({
                        AccessKeyID: this.accessKeyId,
                        SecretAccessKey: this.secretAccessKey,
                        Region: "us-east-2",
                        params: {
                        bucket: "rulebook.files",
                        },
                    });
                    var options = {
                        Bucket: "rulebook.files",
                        Key: `${folderName}/${fileName}`,
                    };
                    s3.getObject(options, function(err, data) {
                        if (err) {
                            console.log(err, err.stack);
                            reject(err)
                        } else {
                            const blob = new Blob([data.Body], { type: data.ContentType });
                            resolve(window.URL.createObjectURL(blob));
                        }
                    });
                }catch(err){
                    if(err){
                        reject(err)
                    }
                }
            })
        },
        printPDF() {
            let iframe = document.querySelector('iframe')
            iframe.contentWindow.focus();
            iframe.contentWindow.print();
		},
        downloadFile() {
            let tempLink = document.createElement('a');
            tempLink.href = this.pathSource;
            tempLink.download = this.fileName;
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
		},
    },
}
</script>

<style>
</style>